











































































import Vue from 'vue';
import { required, email } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';
import ValidatedInput from '@/components/forms/ValidatedInput.vue';
import ToastMessage from '@/models/ToastMessage';

const validations = {
  form: {
    username: { required, email },
    password: { required },
  },
};

export default Vue.extend({
  components: { ValidatedInput },
  mixins: [validationMixin],
  validations: validations,
  props: {
    show: {
      type: Boolean,
      default: false,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      message: '',
      form: { username: '', password: '' },
    };
  },
  methods: {
    handleLogin() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.message = this.$t('forms.common.error_message').toString();
        this.$store.commit('addToastMessage', new ToastMessage(this.message, 'bg-warning'));
      } else {
        this.loading = true;
        this.message = '';
        this.$store.dispatch('AuthStoreModule/login', this.form).then(
          () => {
            this.$emit('update:show', false);
            const message = this.$t('components.LoginModal.success').toString();
            this.$store.commit('addToastMessage', new ToastMessage(message, 'bg-success'));
          },
          (error) => {
            this.loading = false;
            this.message = error;
          }
        );
      }
    },

    onUpdateShow(newShow: boolean) {
      this.$emit('onUpdateShow', newShow);
    },
  },
});
