















import Vue from 'vue';
import UserLocation from '@/models/UserLocation';

export default Vue.extend({
  methods: {
    sortBy(sortBy: string) {
      this.$emit('onSortByChange', sortBy);
    },
  },
  computed: {
    ratingColor(): string {
      return 'Rating' === this.sortedBy ? 'info' : 'secondary';
    },
    priceColor(): string {
      return 'Price' === this.sortedBy ? 'info' : 'secondary';
    },
    alphabetColor(): string {
      return 'Alphabet' === this.sortedBy ? 'info' : 'secondary';
    },
    distanceColor(): string {
      return 'Distance' === this.sortedBy ? 'info' : 'secondary';
    },
    showLocationFilter(): boolean {
      return !!this.userLocation.lat;
    },
  },
  props: {
    sortedBy: {
      type: String,
      required: false,
      default: 'None',
    },
  },
  data() {
    return {
      userLocation: new UserLocation(),
    };
  },
  mounted() {
    this.userLocation = this.$store.state.SearchStoreModule.searchParameter.userLocation;
  },
});
