






















































































import Vue, { PropType } from 'vue';
import PlayroomsIndexPlayroom from '@/models/PlayroomsIndexPlayroom';
import addHours from 'date-fns/addHours';
import format from 'date-fns/format';
import { de } from 'date-fns/locale';
import FormaterMixin from '@/mixins/FormaterMixin.vue';

export default Vue.extend({
  mixins: [FormaterMixin],
  props: {
    searchedBookingType: {
      type: String,
      required: false,
    },
    playroom: {
      type: Object as PropType<PlayroomsIndexPlayroom>,
      required: true,
    },
    searchedCountOfGuests: {
      type: String,
      required: true,
    },
    searchedCountOfHours: {
      type: String,
      required: false,
    },
    searchedCountOfNights: {
      type: String,
      required: false,
    },
  },
  computed: {
    isDarkMode(): boolean {
      return this.$store.state.UiStoreModule.darkModeEnabled;
    },

    cardClass(): string {
      return this.isDarkMode ? 'playroom-dark' : 'playroom-light';
    },

    hasCustomUrl(): boolean {
      return !!this.playroom.companyUrlPath && !!this.playroom.playroomUrlPath;
    },

    routeData(): { name: string; params: {} } {
      if (this.hasCustomUrl) {
        return {
          name: 'PublicCustomUrlPlayroom',
          params: {
            companyUrlPath: this.playroom.companyUrlPath,
            playroomUrlPath: this.playroom.playroomUrlPath,
          },
        };
      } else {
        return {
          name: 'PublicPlayroomDetail',
          params: { playroomId: this.playroom.id },
        };
      }
    },

    pricePerHour(): number {
      return parseFloat(this.playroom.pricePerHour);
    },

    pricePerNight(): number {
      return parseFloat(this.playroom.pricePerNight);
    },

    pricePerBookingForHourly(): number {
      return parseFloat(this.playroom.pricePerBookingForHourly);
    },

    pricePerBookingForOvernight(): number {
      return parseFloat(this.playroom.pricePerBookingForOvernight);
    },

    hourlyPrice(): number {
      return parseFloat(this.playroom.hourlyPrice);
    },

    showPricePerHour(): boolean {
      return !!this.playroom.pricePerHour && this.searchedBookingType != 'overnight';
    },

    showHourlyPrice(): boolean {
      return !!this.playroom.hourlyPrice && this.searchedBookingType != 'overnight';
    },

    showOvernightPrice(): boolean {
      return !!this.playroom.overnightPrice && this.searchedBookingType != 'hourly';
    },

    showPricePerBooking(): boolean {
      return !(this.showHourlyPrice || this.showOvernightPrice);
    },

    showPricePerNight(): boolean {
      return !!this.playroom.pricePerNight && this.searchedBookingType != 'hourly';
    },

    hourlyFreeCancelationDate(): string {
      return this.addHoursAndFormatDate(this.playroom.hourlyMinHoursBeforeFreeCancellation);
    },

    overnightFreeCancelationDate(): string {
      return this.addHoursAndFormatDate(this.playroom.overnightMinHoursBeforeFreeCancellation);
    },

    sameCancelationDate(): boolean {
      return (
        this.playroom.overnightMinHoursBeforeFreeCancellation == this.playroom.hourlyMinHoursBeforeFreeCancellation
      );
    },
    ratingInterpretation(): string {
      if (this.playroom.rating > 4.5) {
        return 'enums.ratingInterpretation.45';
      } else if (this.playroom.rating > 4) {
        return 'enums.ratingInterpretation.40';
      } else if (this.playroom.rating > 3.5) {
        return 'enums.ratingInterpretation.35';
      } else if (this.playroom.rating > 3) {
        return 'enums.ratingInterpretation.30';
      } else {
        return 'enums.ratingInterpretation.0';
      }
    },
  },

  methods: {
    addHoursAndFormatDate(hours: number): string {
      if (hours == undefined) {
        hours = 0;
      }
      const date = addHours(new Date(), hours);
      return format(date, 'Pp', { locale: de });
    },
  },
});
