


















































import Vue from 'vue';
import PlayroomPublicService from '@/services/public/PublicPlayroomService';
import PlayroomCard from '@/components/playrooms/PlayroomCard.vue';
import PlayroomsIndexPlayroom from '@/models/PlayroomsIndexPlayroom';
import { SaveStatusType } from '@/enums/SaveStatusType';
import ToolBelt from '@/components/playroomSearch/ToolBelt.vue';
import SearchMap from '@/components/playroomSearch/SearchMap.vue';
import SearchBar from '@/components/playroomSearch/SearchBar.vue';
import SearchParameter from '@/models/SearchParameter';
import { MetaInfo } from 'vue-meta';

export default Vue.extend({
  components: {
    PlayroomCard,
    SearchBar,
    ToolBelt,
    SearchMap,
  },
  metaInfo(): MetaInfo {
    return { title: this.$t('pages.playroomSearch.meta.title').toString() };
  },
  data() {
    return {
      searchParameter: new SearchParameter(),
      playrooms: [] as PlayroomsIndexPlayroom[],
      guestCountSaveStatus: SaveStatusType.Unknown,
      ready: false,
      currentPage: 1,
      totalPages: 0,
      searchedBookingType: '',
      searchedCountOfHours: 0,
      searchedCountOfNights: 0,
      searchedCountOfGuests: 0,
      searchedCheckInTime: null,
      sortBy: 'None',
      sortedBy: 'None',
      playroomCount: 0,
      markedPlayroomId: '',
      pageIndex: 0,
    };
  },
  computed: {
    maxDistance(): number {
      return this.$store.state.SearchStoreModule.searchParameter.maxDistance;
    },
    firstItemNumber(): number {
      return (this.currentPage - 1) * 20 + 1;
    },
    lastItemNumber(): number {
      return (this.currentPage - 1) * 20 + this.playrooms.length;
    },
  },
  methods: {
    onSortByChange(sortBy: string) {
      this.sortBy = sortBy;
      this.loadPlayrooms();
    },

    setBreadCrumb() {
      this.$store.commit('UiStoreModule/setBreadcrumbItems', [
        { translationKey: 'pages.playroomSearch.breadcrumb.last' },
      ]);
    },

    onSearchParameterChange(searchParameter: SearchParameter) {
      this.searchParameter = searchParameter;
      this.loadPlayrooms();
    },

    loadPlayrooms() {
      this.ready = false;
      //Set default sort when search by location
      let userLocation = this.$store.state.SearchStoreModule.searchParameter.userLocation;
      if (this.sortBy === 'None' && !!userLocation.lat) {
        this.sortBy = 'Distance';
      }
      this.searchParameter.sortBy = this.sortBy;
      this.searchParameter.currentPage = this.currentPage.toString();
      this.$gtag.event('search', { search_term: JSON.stringify(this.searchParameter) });
      PlayroomPublicService.getIndexWithPost(this.searchParameter, this.$store.state.SearchStoreModule).then(
        (response) => {
          this.$nextTick(() => {
            this.ready = true;
          });
          this.totalPages = response.value.playrooms.totalPages;
          this.playroomCount = response.value.playrooms.totalItemCount;
          this.pageIndex = response.value.playrooms.pageIndex;
          this.playrooms = (response.value as {
            playrooms: { items: PlayroomsIndexPlayroom[] };
          }).playrooms.items;
          PlayroomPublicService.getFavorites().then((response) => {
            const playroomIds = response.value as string[];
            this.playrooms.forEach((playroom) => {
              playroom.isFavorite = playroomIds.includes(playroom.id);
            });
          });
          this.searchedBookingType = response.value.bookingType;
          this.searchedCountOfHours = response.value.countOfHours;
          this.searchedCountOfNights = response.value.countOfNights;
          this.searchedCountOfGuests = response.value.countOfGuests;
          this.searchedCheckInTime = response.value.checkInTime;
          this.sortedBy = response.value.sortedBy;
          for (const playroom of this.playrooms) {
            Vue.set(playroom, 'distanceToUser', 0);
          }
        },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (error: any) => {
          console.log('Error:', error);
        }
      );
    },
  },

  mounted() {
    this.setBreadCrumb();
    this.loadPlayrooms();
  },

  watch: {
    currentPage: function (newValue: number, oldValue: number) {
      if (oldValue !== newValue) {
        this.loadPlayrooms();
      }
    },
  },
});
