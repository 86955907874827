

















































































import Vue, { PropType } from 'vue';
import PlayroomLocationPreview from '@/components/playrooms/PlayroomLocationPreview.vue';
import PlayroomsIndexPlayroom from '@/models/PlayroomsIndexPlayroom';
import UserLocation from '@/models/UserLocation';

export default Vue.extend({
  components: {
    PlayroomLocationPreview,
  },
  props: {
    searchedBookingType: {
      type: String,
    },
    searchedCountOfGuests: {
      type: Number,
    },
    searchedCountOfHours: {
      type: Number,
    },
    searchedCountOfNights: {
      type: Number,
    },
    playrooms: {
      type: Array as PropType<PlayroomsIndexPlayroom[]>,
      required: true,
    },
    maxDistance: {
      type: Number,
    },
    markedPlayroomId: {
      type: String,
    },
  },
  watch: {
    markedPlayroomId(newMarkedPlayroomId, oldMarkedPlayroomId) {
      if (oldMarkedPlayroomId) {
        let markers = (this.$refs.markers as any).filter((marker: any) => {
          return marker.options.markerId === oldMarkedPlayroomId;
        });
        if (markers && markers.length > 0) {
          const marker = markers[0].$markerObject;
          if (marker.getAnimation() !== null) {
            marker.setAnimation(null);
          }
        }
      }
      if (newMarkedPlayroomId) {
        let markers = (this.$refs.markers as any).filter((marker: any) => {
          return marker.options.markerId === newMarkedPlayroomId;
        });
        if (markers && markers.length > 0) {
          const marker = markers[0].$markerObject;
          marker.setAnimation((globalThis as any).google.maps.Animation.BOUNCE);
        }
      }
    },
  },
  data() {
    return {
      isPinned: false,
      infoWinOpen: false,
      infoWindowPos: { lat: 0, lng: 0 },
      playroomLocationPreview: {} as PlayroomsIndexPlayroom,
      currentMidx: 0,
      infoOptions: {
        pixelOffset: {
          width: 0,
          height: -35,
        },
        maxWidth: 540,
      },
    };
  },
  mounted() {
    (this.$refs.mapRef as any).$mapPromise.then((map: any) => {
      const centerControlDiv = document.createElement('div');
      this.buttonForMap(
        centerControlDiv,
        this.$t('pages.playroomSearch.mapCenterButton').toString(),
        this.$t('pages.playroomSearch.mapCenterButtonTooltips').toString(),
        () => map.setCenter({ lat: 51.2, lng: 10.4 })
      );
      map.controls[(globalThis as any).google.maps.ControlPosition.TOP_CENTER].push(centerControlDiv);
      map.addListener('click', () => {
        this.infoWinOpen = false;
      });
    });
  },
  computed: {
    userLocation(): UserLocation {
      return this.$store.state.SearchStoreModule.searchParameter.userLocation;
    },
    mapTypeControlOptions(): { mapTypeIds: string[] } {
      return {
        mapTypeIds: [
          'roadmap',
          'satellite',
          // (globalThis as any).google.maps.MapTypeId.ROADMAP,
          // (globalThis as any).google.maps.MapTypeId.SATELLITE,
        ],
      };
    },
    playroomsWithCircle(): Array<PlayroomsIndexPlayroom> {
      return this.filteredPlayroomsOnMap.filter((playroom) => {
        return playroom.addressRadius !== null;
      });
    },

    playroomsWithMarker(): Array<PlayroomsIndexPlayroom> {
      return this.filteredPlayroomsOnMap;
    },

    filteredPlayroomsOnMap(): Array<PlayroomsIndexPlayroom> {
      return this.playrooms.filter((playroom) => {
        return playroom.latitude != null && playroom.longitude != null;
      });
    },
  },
  methods: {
    onPinMap() {
      this.isPinned = !this.isPinned;
    },

    buttonForMap(centerControlDiv: HTMLDivElement, text: string, title: string, callback: Function) {
      // Set CSS for the control border.
      const controlUI = document.createElement('div');
      controlUI.style.backgroundColor = '#fff';
      controlUI.style.border = '2px solid #fff';
      controlUI.style.borderRadius = '3px';
      controlUI.style.boxShadow = '0 2px 6px rgba(0,0,0,.3)';
      controlUI.style.cursor = 'pointer';
      controlUI.style.marginTop = '8px';
      controlUI.style.marginBottom = '22px';
      controlUI.style.textAlign = 'center';
      controlUI.title = title;
      centerControlDiv.appendChild(controlUI);
      // Set CSS for the control interior.
      const controlText = document.createElement('div');
      controlText.style.color = 'rgb(25,25,25)';
      controlText.style.fontFamily = 'Roboto,Arial,sans-serif';
      controlText.style.fontSize = '16px';
      controlText.style.lineHeight = '38px';
      controlText.style.paddingLeft = '5px';
      controlText.style.paddingRight = '5px';
      controlText.innerHTML = text;
      controlUI.appendChild(controlText);
      // Setup the click event listeners: simply set the map to Chicago.
      controlUI.addEventListener('click', () => {
        callback();
      });
    },

    toggleInfoWindow(marker: PlayroomsIndexPlayroom, index: number) {
      this.infoWindowPos = { lat: marker.latitude, lng: marker.longitude };
      this.playroomLocationPreview = marker;

      //check if its the same marker that was selected if yes toggle
      if (this.currentMidx == index) {
        this.infoWinOpen = !this.infoWinOpen;
      }
      //if different marker set infowindow to open and reset current marker index
      else {
        this.infoWinOpen = true;
        this.currentMidx = index;
      }
    },
  },
});
