




































import Vue from 'vue';

export default Vue.extend({
  props: {
    rating: {
      default: () => ({ id: 'id', rating: '0' }),
    },
    displayInline: {
      type: Boolean,
      default: false,
      required: false,
    },
    ratingCount: {
      type: Number,
      required: false,
    },
    withRatingDescription: {
      type: Boolean,
      default: false,
      required: false,
    },
    showText: {
      type: Boolean,
      default: true,
      required: false,
    },
  },
  computed: {
    text(): string {
      if (this.isDarkMode) {
        return this.$t('components.Rating.textDarkMode').toString();
      } else {
        return this.$t('components.Rating.textWhiteMode').toString();
      }
    },

    stringRation(): string {
      return this.intRating.toLocaleString('de', {
        maximumFractionDigits: 1,
        minimumFractionDigits: 1,
      });
    },

    intRating() {
      const intRating = parseInt(this.rating.rating);
      if (!isNaN(intRating) && intRating >= 0 && intRating <= 5) {
        return intRating;
      } else {
        return 0;
      }
    },

    isDarkMode(): boolean {
      return this.$store.state.UiStoreModule.darkModeEnabled;
    },

    ratingTranslationKey(): string {
      const rating = parseFloat(this.rating.rating);
      if (rating >= 4.5) {
        return 'rating45';
      }
      if (rating >= 4) {
        return 'rating40';
      }
      if (rating >= 3.5) {
        return 'rating35';
      }
      if (rating >= 3) {
        return 'rating30';
      }
      return '';
    },
  },
  methods: {
    image(full: boolean) {
      let color = '';
      if (this.isDarkMode) {
        color = '_white';
      }
      let status = 'empty';
      if (full) {
        status = 'full';
      }
      return '/images/icons/butt_' + status + color + '.png';
    },
  },
});
