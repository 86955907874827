import ApiService from '@/services/ApiService';

const API_URL = '/api/Customer/PlayroomFavorite/';

class CustomerPlayroomFavoriteService {
  toggleFavorite(playroomId: string) {
    return ApiService.post(`${API_URL}Toggle`, {
      playroomId: playroomId,
    });
  }
}

export default new CustomerPlayroomFavoriteService();
